import { NextPage } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import NextRouteConfig from "../next-routes/NextRouteConfig";
import GenericSetHead from "../src/components/shared-components/GenericSetHead";
import FullScreenSpinner from "../src/components/shared-components/Spinner/FullScreenSpinner";
import BrandButton from "../src/components/shared-components/custom-components/BrandButton";
import { BrandContext } from "../src/contexts/BrandContext";
import QuestionMark from "/public/icons/questionMark.svg";

const _404: NextPage = () => {
  const router = useRouter();
  const { setIs404Page: setNoDashAndBanner } = useContext(BrandContext);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // No Dashboard And Banner On 404 Page
    setNoDashAndBanner?.(true);

    const timeout = setTimeout(() => setLoading(false), 1000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      setNoDashAndBanner?.(false);
    };
  }, [setNoDashAndBanner]);

  return (
    <>
      <GenericSetHead title="404 - Page Not Found!" />
      {loading ? (
        <FullScreenSpinner />
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <Image
            src={QuestionMark}
            alt="Question Mark"
            className="max-w-md md:w-1/2"
          />
          <span className="block mt-8 text-4xl font-extrabold text-center 2xl:text-3xl">
            Page Not Found
          </span>
          <span className="block my-4 text-base font-bold text-center 2xl:text-xl">
            Recheck the link you have clicked on!
          </span>
          <BrandButton
            type="button"
            theme="primary"
            onClick={() => {
              router.replace(NextRouteConfig.login);
            }}
            className="h-12 text-base font-semibold"
          >
            Go To Home
          </BrandButton>
        </div>
      )}
    </>
  );
};

export default _404;
